<div class="header mb-4">
    <div class="container">
    <div class="row">
        <div class="col-md-4">
            <a class="ml-2" href="https://www.insuremyalpaca.co.uk/">
                <img src="assets/images/hhib.png" alt="logo" style="height: 114px;" class="img-fluid">
                
            </a>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-5 pt-5 text-right">
            <a href="tel:01228406290" class="text-white"><img src="assets/images/phone.jpg" alt="Phone" style="width: 22px;"> 01228 406290</a>  &nbsp;&nbsp;&nbsp; 
            <a href="mailto:alpaca@hhib.co.uk" class="text-white"><img src="assets/images/email.jpg" alt="Email" class="mr-2" style="width: 15px;"> alpaca@hhib.co.uk</a> 
        </div>
    </div>
    </div>
</div>

<div class="container">
    <router-outlet></router-outlet>
</div>

<div class="alpacafooter">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-2 text-center">
                <p>Please contact us to discuss your requirements</p>
                <p>t: 01228 406290 &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; e: alpaca@hhib.co.uk &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp; insuremyalpaca.co.uk</p>
                <p class="f13">Insure My Alpaca is a trading name of H&H Insurance Brokers Limited, which is authorised and regulated by the FCA No: 305809</p>
                
                <p class="f13">
                This product is underwritten by Peliwica Insurance, Peliwica Insurance is a Lloyd’s cover holder, authorised and regulated by The Financial Conduct Authority (FCA): registered number 808026
                </p>
            </div>
        </div>
    </div>
</div>