export const environment = {
  staging: true,
  production: false,
  apiURL: "https://staging.api.peliwica.easyukhosting.co.uk/api/",
  imageURL: "https://staging.api.peliwica.easyukhosting.co.uk",
  ADDRESSLOOKUP_API_URL: "https://api.getAddress.io/find/",
  ADDRESSLOOKUP_API_KEY: "cIc_-chj3kiI0S4t6jyUFQ27891",
  EQUINE_ID: '5dd8c0bb-b933-4f28-bc70-b5cc47fafb95',
  HHID: '24853ed2-c893-472c-8bec-d040c76cad08',
  CLIENT_WEBSITE_LINK: 'http://insuremyalpaca.co.uk/',
  ALPACA_ID: '6dd8c0bb-b933-4f28-bc70-b5cc47fafb95'
};