<form #f="ngForm" (ngSubmit)="onSubmit(f)" enctype="multipart/form-data">
    <div *ngFor="let statementOfFact of statementOfFacts; let i = index;">
        <div
            *ngIf="(statementOfFact.product_type == 2 && quote.quote_versions[0].alpaca_theft_cover == 1) || (statementOfFact.product_type == 3 && quote.quote_versions[0].alpaca_public_liability_cover == 1) || (statementOfFact.product_type == 4 && quote.quote_versions[0].alpaca_trailer_cover == 1)">
            <!-- Alpaca/LLAMA -->
            <h5 class="mt-4" *ngIf="statementOfFact.quesTitles">{{ statementOfFact.quesTitles }}</h5>
            <div
                [hidden]="quote.quote_versions[0].about_alpaca.does_vet_treatments_covered == 2 && statementOfFact.is_special_vet == 1 && statementOfFact.product_type == 2">
                <div class="form-group">
                    <div class="alert alert-primary">
                        <label class="f13">
                            {{ statementOfFact.description }}
                        </label>
                    </div>
                </div>
                <div class="form-group row"
                    *ngIf="statementOfFact.type == 1 && quote.quote_versions[0].statement_of_facts.length > 0">
                    <div class="col-md-3">
                        <mat-radio-group aria-label="Select an option"
                            name="statementOfFact_{{quote.quote_versions[0].statement_of_facts[i].question_id}}"
                            [(ngModel)]="quote.quote_versions[0].statement_of_facts[i].response" required
                            #statementOfFact="ngModel" (change)="onQuestionsChange($event,statementOfFact)">
                            <div class="quote_radio row">
                                <div class="col-md-6">
                                    <mat-radio-button class="p-2" style="background-color: #ccc;" [value]="1"
                                        name="statementOfFact_{{quote.quote_versions[0].statement_of_facts[i].question_id}}"
                                        ngDefaultControl
                                        [(ngModel)]="quote.quote_versions[0].statement_of_facts[i].response" required
                                        #statementOfFactControl="ngModel">Yes</mat-radio-button>
                                </div>
                                <div class="col-md-6">
                                    <mat-radio-button class="p-2" style="background-color: #ccc;" [value]="2"
                                        name="statementOfFact_{{quote.quote_versions[0].statement_of_facts[i].question_id}}"
                                        ngDefaultControl
                                        [(ngModel)]="quote.quote_versions[0].statement_of_facts[i].response" required
                                        #statementOfFactControl="ngModel">No</mat-radio-button>
                                </div>
                            </div>
                            <div *ngIf="f.submitted && statementOfFactControl.invalid"
                                class="invalid-feedback col-md-12">
                                Please choose an option.
                            </div>
                        </mat-radio-group>

                    </div>
                </div>
                <div *ngIf="statementOfFact.is_exclusion == 1 && quote.quote_versions[0].statement_of_facts.length > 0 && quote.quote_versions[0].statement_of_facts[i].response == 2"
                    class="alert alert-secondary f13">
                    {{ statementOfFact.exclusion_text }}
                </div>
                <div class="form-group"
                    *ngIf="statementOfFact.is_exclusion == 0 && statementOfFact.is_decline == 0 && quote.quote_versions[0].statement_of_facts.length > 0 && quote.quote_versions[0].statement_of_facts[i].response == 2">
                    <textarea name="response_text_{{quote.quote_versions[0].statement_of_facts[i].question_id}}"
                        class="form-control"
                        placeholder="Please enter details here, your quote will be refered to one of our underwriters"
                        [(ngModel)]="quote.quote_versions[0].statement_of_facts[i].response_text"
                        #response_text="ngModel" required
                        [ngClass]="{ 'is-invalid': f.submitted && response_text.invalid }">
                </textarea>
                    <div *ngIf="f.submitted && response_text.invalid" class="invalid-feedback">
                        Response text is required.
                    </div>
                </div>
                <div *ngIf="statementOfFact.is_decline == 1 && quote.quote_versions[0].statement_of_facts.length > 0 && quote.quote_versions[0].statement_of_facts[i].response == 2"
                    class="alert alert-secondary f13">
                    {{ statementOfFact.exclusion_text }}
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="quote.quote_versions[0].alpaca_theft_cover == 1">
        <div class="form-group row mt-5">
            <div class="col-md-11">
                <label>Veterinary Certificates of Health are required for crias that are fewer than 90 days old and for
                    alpacas/llamas that have a value of more than &pound;5,000</label><br>
                <div class="form-group">
                    <a (click)="uploadDocumentBtn(documents)" class="btn btn-success">Upload Document</a>
                </div>
            </div>
        </div>
        <ag-grid-angular style="width: 100%; height: 230px;float:left;" class="ag-theme-bootstrap"
            [gridOptions]="gridOptions" [rowData]="rowData" [columnDefs]="columnDefs" animateRows pagination
            paginationPageSize=5 rowHeight=27 headerHeight=35
            (rowClicked)='onGridRowClicked($event,documents,documentsDelete)'>
        </ag-grid-angular>
    </div>

    <div class="form-group">
        <a (click)="backToFullDetailsTab()" class="btn btn-default btn-gray mr-3">Back</a>
        <input type="submit" name="proceed" class="btn btn-success btn-gray" value="Proceed to quote details"
            [disabled]="disableBtn">
    </div>
</form>
<ng-template #documents let-modal>
    <div class="modal-header">
        <h4>Document</h4>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Title</label>
            <input type="text" name="document_title" [(ngModel)]="docTitle" class="form-control form-control-sm">
        </div>
        <div class="form-group" *ngIf="!documentsEditMode">
            <label>Document</label>
            <div class="custom-file">
                <input type="file" name="file_name" (change)="getUploadedFile($event.target.files)"
                    class="custom-file-input" id="customFile">
                <label class="custom-file-label" for="customFile">{{quote.quote_document}}</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" *ngIf="!documentsEditMode" class="btn btn-success"
            (click)="documentUpload()">Upload</button>
        <button type="button" *ngIf="documentsEditMode" class="btn btn-success"
            (click)="updateDocument()">Update</button>
        <button type="button" class="btn btn-default btn-gray" (click)="modal.close()">Close</button>
    </div>
</ng-template>

<ng-template #documentsDelete let-modal>
    <div class="modal-header">
        <h4>Delete Document</h4>
    </div>
    <div class="modal-body text-center">
        <img src="assets/images/deactivate.png" width="50"><br>
        Are you sure you want to delete document {{docTitle}}?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="deleteDocument()">Yes</button>
        <button type="button" class="btn btn-default" (click)="modal.close()">No</button>
    </div>
</ng-template>